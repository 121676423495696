<template>
  <div class="height-100pc mx-3 shop-list">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="8">
        <div class="mb-8">
          <v-text-field
            v-model="filter"
            label="search"
            clearable
          ></v-text-field>
        </div>
        <span v-if="$store.getters.shopById.length != 0">
          <template v-for="shop in $store.getters.shopById">
            <shop-list-item
              :key="shop._id"
              :shop="shop"
              v-if="shop.title.toUpperCase().includes(filter.toUpperCase())"
            />
          </template>
        </span>
        <span v-else>
          {{ $t("shop.list.no_shops") }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ShopListItem from "@/components/Shop/ShopListItem";
export default {
  name: "ShopList",
  components: { ShopListItem },
  data() {
    return { filter: "" };
  },
  mounted() {
    console.log("$store.getters.shopById.length", this.$store.getters.shopById);
  },
};
</script>
