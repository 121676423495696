<template>
  <v-card class="py-2 mb-10 height-100pc shop-card" elevation="2">
    <v-card-title class="py-0 text-center">{{ shop.title }}</v-card-title>
    <v-card-subtitle class="py-0 my-0 subtitle-1 text-center">
      {{ shop.coordinates.streetNumber }} {{ shop.coordinates.streetName }}
      {{ shop.coordinates.zipcode }}
      {{ shop.coordinates.city }}
    </v-card-subtitle>
    <v-divider class="mx-4"></v-divider>
    <v-row align="center" class="mx-0">
      <v-col class="py-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="py-0 my-0 text-center">
              <v-progress-circular v-if="loading" indeterminate color="green" />
              <span v-else class="transition-swing text-md-h4 text-h5">{{
                shop.nDevices
              }}</span></v-list-item-title
            >
            <v-list-item-subtitle class="py-0 my-0 text-center">{{
              $t("shop.list.item.device")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="py-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="py-0 my-0 text-center">
              <v-progress-circular v-if="loading" indeterminate color="green" />
              <span v-else class="transition-swing text-md-h4 text-h5">{{
                pendingCCS
              }}</span></v-list-item-title
            >
            <v-list-item-subtitle class="py-0 my-0 text-center">{{
              $t("shop.list.item.pendingCCS")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="py-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="py-0 my-0 text-center">
              <v-progress-circular v-if="loading" indeterminate color="green" />
              <span v-else class="transition-swing text-md-h4 text-h5">{{
                pendingCustomer
              }}</span></v-list-item-title
            >
            <v-list-item-subtitle class="py-0 my-0 text-center">{{
              $t("shop.list.item.pendingCustomer")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col class="py-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="py-0 my-0 text-center">
              <v-progress-circular v-if="loading" indeterminate color="green" />
              <span v-else class="transition-swing text-md-h4 text-h5">{{
                free
              }}</span></v-list-item-title
            >
            <v-list-item-subtitle class="py-0 my-0 text-center">{{
              $t("shop.list.item.free")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-divider class="mx-4"></v-divider>
    <v-row align="center" class="mx-0">
      <v-col>
        Status :
        <b-spinner v-if="loading" />
        <b-icon-exclamation-circle-fill
          variant="critical"
          v-else-if="nbDefects > 0" />
        <b-icon-check-circle-fill variant="success" v-else
      /></v-col>
      <v-col cols="auto" class="ml-auto">
        <!-- TODO: implement shop edition and hide for ccs -->
        <v-list>
          <v-list-item class="px-2">
            <v-btn
              text
              class="mr-1"
              :to="{ name: 'ShopEdit', params: { id: shop._id } }"
            >
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-btn
              elevation="1"
              large
              :to="{ name: 'ShopMain', params: { id: shop._id } }"
            >
              Details
            </v-btn>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "ShopListItem",
  data() {
    return {
      nDevices: 50,
      pendingCCS: 10,
      pendingCustomer: 5,
      nbDefects: 0,
      free: 0,
      loading: true,
    };
  },
  props: ["shop"],
  mounted() {
    this.$http({
      url:
        process.env.VUE_APP_API_BASE_URL +
        this.$api.routes[process.env.VUE_APP_API_VERSION || "v1"].shop_boxes +
        this.shop._id,
      method: "GET",
    }).then((resp) => {
      this.loading = false;
      this.free = resp.data.free;
      this.nbDefects = resp.data.defective;
      this.pendingCCS = resp.data.pendingCcs;
      this.pendingCustomer = resp.data.pendingConsumer;
    });
  },
};
</script>
<style scop>
.v-application .text-center {
  justify-content: center !important;
}
</style>
